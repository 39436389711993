/** @jsx jsx */
import Helmet from 'react-helmet'
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import Button from '../components/Button'
import React from 'react'
import { useFormik, FormikProvider, Form, useField } from 'formik'
import * as Yup from 'yup'
import './styles.css'

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

const TextInputLiveFeedback = ({ label, helpText, ...props }) => {
    const [field, meta] = useField(props)

    // Show inline feedback if EITHER
    // - the input is focused AND value is longer than 2 characters
    // - or, the has been visited (touched === true)
    const [didFocus, setDidFocus] = React.useState(false)
    const handleFocus = () => setDidFocus(true)
    const showFeedback = (!!didFocus && field.value.trim().length > 2) || meta.touched

    return (
        <div
            className={`form-control ${showFeedback ? (meta.error ? 'invalid' : 'valid') : ''
                }`}
        >
            <div className="flex items-center space-between">
                <label htmlFor={props.id}><i>{`${props.req}`}</i>{label}</label>{' '}
                {showFeedback ? (
                    <div
                        id={`${props.id}-feedback`}
                        aria-live="polite"
                        className="feedback text-sm"
                    >
                        {meta.error ? meta.error : '✓'}
                    </div>
                ) : null}
            </div>
            <input
                {...props}
                {...field}
                aria-describedby={`${props.id}-feedback ${props.id}-help`}
                onFocus={handleFocus}
            />
            <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
                {helpText}
            </div>
        </div>
    )
}

const FormArea = () => {
    const formik = useFormik({
        initialValues: {
            prod_name: '',
            prod_name_en: '',
            corp_name: '',
            corp_name_en: '',
            address: '',
            address_en: '',
            zip_code: '',
            contact: '',
            contact_en: '',
            job_title: '',
            job_title_en: '',
            phone: '',
            email: '',
            reason: '',
        },
        onSubmit: async (values) => {
            await sleep(500)
            fetch('https://w.obmed.cn/reg/index.php', {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(values, null, 2)
            })
                .then(res => res.json())
                .catch(error => console.warn('Error:', error))
                .then(response => {
                    if (response) {
                        console.log('Success:', response)
                        if (response.code == 0) {
                            alert(response.msg)
                            location.href = '/'
                        } else {
                            alert(response.msg)
                        }
                    }
                })
        },
        validationSchema: Yup.object({
            prod_name: Yup.string()
                .trim()
                .required('此项为必填项'),
            prod_name_en: Yup.string()
                .trim()
                .required('此项为必填项'),
            corp_name: Yup.string()
                .trim()
                .required('此项为必填项'),
            corp_name_en: Yup.string()
                .trim()
                .required('此项为必填项'),
            address: Yup.string()
                .trim()
                .required('此项为必填项'),
            address_en: Yup.string()
                .trim()
                .required('此项为必填项'),
            zip_code: Yup.string()
                .trim()
                .required('此项为必填项'),
            contact: Yup.string()
                .trim()
                .required('此项为必填项'),
            contact_en: Yup.string()
                .trim()
                .required('此项为必填项'),
            job_title: Yup.string()
                .trim()
                .required('此项为必填项'),
            job_title_en: Yup.string()
                .trim()
                .required('此项为必填项'),
            phone: Yup.string()
                .trim()
                .required('此项为必填项'),
            email: Yup.string()
                .email('必须输入有效 E-mail 地址')
                .trim()
                .required('此项为必填项'),
            reason: Yup.string(),
        }),
    })

    return (
        <FormikProvider value={formik}>
            <Form>
                <TextInputLiveFeedback
                    req="*"
                    label="产品名称（中文）"
                    id="prod_name"
                    name="prod_name"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="产品名称（英文）"
                    id="prod_name_en"
                    name="prod_name_en"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="公司名称（中文）"
                    id="corp_name"
                    name="corp_name"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="公司名称（英文）"
                    id="corp_name_en"
                    name="corp_name_en"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="联系地址（中文）"
                    id="address"
                    name="address"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="联系地址（英文）"
                    id="address_en"
                    name="address_en"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="邮政编码"
                    id="zip_code"
                    name="zip_code"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="联系人（中文）"
                    id="contact"
                    name="contact"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="联系人（英文）"
                    id="contact_en"
                    name="contact_en"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="职位（中文）"
                    id="job_title"
                    name="job_title"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="职位（英文）"
                    id="job_title_en"
                    name="job_title_en"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="电话号码"
                    id="phone"
                    name="phone"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req="*"
                    label="E-mail"
                    id="email"
                    name="email"
                    helpText=""
                    type="text"
                />
                <TextInputLiveFeedback
                    req=""
                    label="申请原因"
                    id="reason"
                    name="reason"
                    helpText=""
                    type="text"
                />
                <div>
                    <button type="submit">提交</button>
                    <button type="reset">重置</button>
                </div>
            </Form>
        </FormikProvider>
    )
}

function RegisterPage() {
    return (
        <Layout>
            <Helmet>
                <title>自助FDA注册 - FDA助手</title>
            </Helmet>
            <div
                sx={{
                    paddingY: 9,
                    paddingX: 5,
                }}
            >
                <h1
                    sx={{
                        margin: 0,
                        fontSize: 6,
                        fontWeight: 'normal',
                        textAlign: 'center',
                    }}
                >
                    自助FDA注册
                </h1>
                <div className="app">
                    <p className="text-lg"><em>注意</em>：以下信息将作为贵单位及产品在美国 FDA 登记注册的内容，请谨慎对待避免出错。</p>
                    <div className="form">
                        <FormArea />
                    </div>
                </div>
                <Button
                    as="a"
                    href="/"
                    sx={{ display: 'block', width: '50%', variant: 'buttons.outline', marginTop: 10, marginX: 'auto' }}
                >
                    返回首页
                </Button>
            </div>
        </Layout>
    )
}

export default RegisterPage
